<template>
  <div class="" :ref="'icon-' + hashId"></div>
</template>

<script>
import hashicon from 'hashicon';
import { getHashIcon } from '../../utils/hashIcon';

export default {
  name: 'ClHashIcon',
  props: {
    hashId: String,
    spaceName: String,
  },
  mounted() {
    const hash = this.hashId;
    const params = { size: 32 };
    const icon = hashicon(hash, params);
    if (this.spaceName === localStorage.getItem('zq-space-name')) {
      getHashIcon(
        this.$refs['icon-' + this.hashId],
        localStorage.getItem('zq-space-name')
      );
    } else {
      this.$refs['icon-' + this.hashId].appendChild(icon);
    }
  },
};
</script>

<style scoped></style>
